const Items = [
{
    title: 'Resource',
    title1: 'Press',
    title2: 'Faqs',
    title3: 'Jobs',
},

{
    title: 'Company',
    title1: 'Terms and Use',
    title2: 'Privacy Policy',
    title3: 'Blogs',
    title4: 'Sitemap',
},
];

export default Items